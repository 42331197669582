body {
  font-family: 'DM Sans', sans-serif;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

* {
  box-sizing: border-box;
}

.main_div {
  display: flex;
  width: 100vw;
  height: 100dvh;
  /* align-items: center; */
  position: relative;
}

/* MENU */

.bt_menu a {
  color: #000000;
  font-weight: 300;
  letter-spacing: 2px;
  text-decoration: none;
  padding: 10px 2px;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

.bt_menu {
  padding: 0;
}

.bt_menu li {
  list-style: none;
}

/* CONTENT */

.div_projects {
  background-color: rgba(255, 255, 255, 0.85);
  /* background-color: #0087c4; */
}

.div_front {
  z-index: 10;
}

.div_behind {
  z-index: 9;
}

/* TESTE */

.testeCantos {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border: 2px solid #000000;
  position: absolute;
}

/* HOME */

.home_div_1 {
  position: absolute;
  top: 0;
  height: 100vh;
}

.home_div_1 {
  /* background-color: #cecece; */
  background-color: #232323;
  /* background-color: #FBAB7E;
  background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%); */
}

#obj_canvas_1,
#obj_canvas_2,
#obj_canvas_3,
#obj_canvas_4 {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  pointer-events: all;
}

.cont_text_home h1 {
  margin-bottom: 10px;
  color: #fff200;
  text-shadow: 0 0 16px #000;
}

.cont_text_home p {
  color: #ffffff;
  text-shadow: 0 0 16px #000;
  margin-top: 10px;
}

.cont_text_home {
  height: auto;
  z-index: 12;
  position: absolute;
  left: 2%;
}


/* PORTFOLIO */
.portfolio_client_bar {
  height: auto;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
}

.title_clients {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 5px 0 0 0;
}

.portfolio_client_logos_ul {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: space-around;
}

.portfolio_client_logos_ul li {
  padding: 10px;
  text-align: center;
}

.portfolio_client_logos_ul img {
  width: 100%;
  max-width: 50px;
}

.content_portfolio {
  width: 100%;
  height: 100%;
  /* border-left: 1px solid #bbc1cb; */
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 100px 100px;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.portfolio_title_category {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  color: #ff1d25;
}

.title_portfolio {
  font-size: 75px;
  width: 100%;
  font-weight: bold;
  color: #ff1d25;
  height: 200px;
}

.img_portfolio_thumb {
  /* width: 100%;
  height: 100%;
  min-width: 150px;
  max-width: 200px;
  max-height: 200px; */
  width: 200px;
  height: 200px;
  background-color: #ffffff;
  padding: 5px;
  /* border: 4px solid rgba(255, 255, 255, 0.5); */
  margin-top: 1%;
  transition: transform .3s;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img_portfolio_thumb:hover {
  transform: scale(1.8);
  z-index: 12;
}

/* PROJECTS */

.content_projects {
  background-color: rgba(255, 255, 255, 0.85);
}

.content_projects div {
  width: 100%;
  min-height: calc(100% / 8);
  margin: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content_projects p {
  margin: 10px 0;
  line-height: 20px;
}

/* 
.destaque_about {
  color: #ff1d25;
} */

.project_title_category {
  font-weight: bold;
  margin: 0;
  color: #ffffff;
}

.project_subtitle_category {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.bg_wordpress {
  /* background-color: #21759b; */
  background-color: #21759b;
  background-image: linear-gradient(90deg, #086c9b 0%, #248fc0 100%);
}

.bg_illustrator {
  /* background-color: #f98d25; */
  background-color: #f98d25;
  background-image: linear-gradient(90deg, #c96608 0%, #f98d25 100%);
}

.bg_premiere {
  /* background-color: #8c8df6; */
  background-color: #8c8df6;
  background-image: linear-gradient(90deg, #5c5fef 0%, #8c8df6 100%);
}

.bg_photoshop {
  /* background-color: #2d9ffa; */
  background-color: #2d9ffa;
  background-image: linear-gradient(90deg, #0c73c7 0%, #2d9ffa 100%);
}

.bg_after_effects {
  /* background-color: #898af5; */
  background-color: #898af5;
  background-image: linear-gradient(90deg, #4144f5 0%, #898af5 100%);
}

.bg_user_experience {
  /* background-color: #fa759e; */
  background-color: #fa759e;
  background-image: linear-gradient(90deg, #d14770 0%, #fa759e 100%);
}

.bg_php {
  /* background-color: #8f9ed1; */
  background-color: #8f9ed1;
  background-image: linear-gradient(90deg, #4c5f9d 0%, #8f9ed1 100%);

}

.bg_3d {
  /* background-color: #4c4c4c; */
  background-color: #4c4c4c;
  background-image: linear-gradient(90deg, #323333 100%, #4c4c4c 0%);

}

/* SOBRE */

#about_p {
  margin: 0;
}

.cont_text_about {
  z-index: 12;
  position: absolute;
  background-color: #ffffff;
}

.cont_text_about h1 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.cont_text_about p {
  font-size: 16px;
}

/* CONTACT */

.div_content_contact {
  background-color: #ffffff;
  padding: 20px;
  ;
}

.div_contact {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.title_contact {
  font-size: 50px;
  color: #ff1d25;
  font-weight: bold;
  margin: 0;
}

/* Background animation */

#webgi-background,
#webgi-background-canvas {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 0;
  opacity: 1;
}

.div_content_contact a {
  text-decoration: none;
}

.bt_contact {
  background-color: #ff1d25;
  color: #ffffff;
  width: 100%;
  padding: 10px;
  text-align: center;
  text-decoration: none;
}


/* UI */

#bt_engl,
#bt_ptbr {
  width: 33px;
  height: 21px;
  position: absolute;
  cursor: pointer;
}

#bt_engl {
  background-color: #0c73c7;
  background-image: url("/assets/eng_flag.svg");
}

#bt_ptbr {
  background-color: #4144f5;
  background-image: url("/assets/ptbr_flag.svg");
}



.logo_menu {
  cursor: pointer;
}

.div_home,
.div_projects,
.div_portfolio,
.div_about,
.div_contact {
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
}


.content_scroll {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar-button {
  background: #ffffff
}

::-webkit-scrollbar-track-piece {
  background: #ffffff
}

::-webkit-scrollbar-thumb {
  background: #ff1d25
}

/*******************************************************/
@media only screen and (max-width: 720px) {

  /* HOME */
  .home_div_1 {
    width: 100vw;
  }

  .cont_text_home h1 {
    font-size: 40px;
  }

  .cont_text_home p {
    font-size: 17px;
  }

  .cont_text_home {
    top: 35%;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  /* PROJECTS */
  .project_title_category {
    font-size: 22px;
    margin: 0;
  }

  .project_subtitle_category {
    font-size: 15px;
    margin-top: 5px;
    line-height: 20px;
  }

  /* ABOUT */

  .cont_text_about {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .cont_text_about h1 {
    font-size: 35px;
    margin-bottom: 0;
    color: #ff1d25;
  }

  /* CONTACT */

  .div_content_contact {
    width: 100%;
    text-align: center;
  }

  .title_contact {
    font-size: 35px;
  }

  /* UI */

  #bt_engl,
  #bt_ptbr {
    bottom: -10px;
  }

  #bt_engl {
    right: 13px;
  }

  #bt_ptbr {
    right: 50px;
  }

  .menu_projetos:hover {
    color: #ff1d25;
  }

  .menu_portfolio:hover {
    color: #ff1d25;
  }

  .menu_sobre:hover {
    color: #ff1d25;
  }

  .menu_contato:hover {
    color: #ff1d25;
  }

  .div_home,
  .div_projects,
  .div_portfolio,
  .div_about,
  .div_contact {
    height: calc(100vh - 70px);
    top: 70px;
  }

  .menu_sort_logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu_order {
    display: flex;
    align-items: center;
  }

  .menu_sort_logo {
    width: 25vw;
  }

  .menu_sort_menu {
    width: 75vw;
    display: flex;
  }

  .bt_menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }

  .bt_menu a {
    font-size: 13px;
  }

  .svg-logo {
    width: 100%;
    max-width: 70px;
  }

  .menu_bar {
    width: 100vw;
    height: 70px;
    background-color: #ffffff;
    z-index: 20;
    top: 0;
    position: relative;
  }

  .div_home,
  .div_projects,
  .div_portfolio,
  .div_about,
  .div_contact {
    width: 100vw;
    left: 0;
  }

  .div_contact {
    align-items: flex-end;
  }

  ::-webkit-scrollbar {
    width: 1em;
    height: 1em
  }

}

/*******************************************************/
@media only screen and (min-width: 721px) {

  /* HOME */
  .home_div_1 {
    width: 75vw;
  }

  .cont_text_home h1 {
    font-size: 70px;
  }

  .cont_text_home p {
    font-size: 22px;
  }

  .cont_text_home {
    bottom: 0;
  }

  /* PROJECTS */
  .project_title_category {
    font-size: 29px;
    margin: 0;
  }

  .project_subtitle_category {
    font-size: 17px;
    line-height: 24px;
    margin-top: 5px;
  }

  /* ABOUT */

  .cont_text_about {
    width: 100%;
    max-width: 500px;
    height: 100%;
    top: 0;
    left: 0;
  }

  .cont_text_about {
    padding: 20px;
  }

  .cont_text_about h1 {
    font-size: 50px;
    color: #ff1d25;
  }

  /* CONTACT */

  .div_contact {
    align-items: center;
  }

  .div_content_contact {
    padding-top: 30px;
    padding-right: 40px;
    padding-bottom: 30px;
    padding-left: 20px;
  }

  /* UI */

  #bt_engl,
  #bt_ptbr {
    bottom: -20px;
  }

  #bt_engl {
    left: calc(50% + 10px);
  }

  #bt_ptbr {
    right: calc(50% + 10px);
  }

  .menu_order {
    position: relative;
  }

  .menu_projetos,
  .menu_sobre,
  .menu_contato,
  .menu_projetos:hover,
  .menu_sobre:hover,
  .menu_contato:hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .menu_projetos:hover {
    /* background: #0087c4; */
    background: #ff1d25;
    color: #ffffff;
  }

  .menu_portfolio:hover {
    /* background: #0167ac; */
    background: #ff1d25;
    color: #ffffff;
  }

  .menu_sobre:hover {
    /* background: #00558f; */
    background: #ff1d25;
    color: #ffffff;
  }

  .menu_contato:hover {
    background: #ff1d25;
    color: #ffffff;
  }

  .div_home,
  .div_projects,
  .div_portfolio,
  .div_about,
  .div_contact {
    height: 100vh;
    top: 0;
  }

  .bt_menu a {
    background: #dbdbdb;
  }

  .svg-logo {
    width: 100%;
    max-width: 220px;
  }

  .menu_bar {
    width: 25vw;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    z-index: 10;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    padding: 1.5%;
    position: relative;
  }

  .div_home,
  .div_projects,
  .div_portfolio,
  .div_about,
  .div_contact {
    width: 75vw;
    left: 25%;
  }

  ::-webkit-scrollbar {
    width: 1.5em;
    height: 1.5em
  }

}

@media only screen and (max-width: 545px) {}